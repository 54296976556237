import {define, init, inject, singleton} from '@injex/core';
import {computed, makeObservable, observable} from 'mobx';
import {SessionManager} from '../../session/managers/sessionManager.mdl';
import UserManager from '../../../common/managers/UserManager.mdl';
import {BaseReportsManager} from './baseReportsManager';
import SocketManager from '../../../common/managers/SocketManager.mdl';
import ReportingParamsManager from './reportingParamsManager.mdl';
import {IReportHistory, IReportHistoryParams} from '../common/interfaces/IReportHistory';
import {AlertsManager} from "../../../common/alerts/managers/alertsManager.mdl";
import {AlertAccent} from "../../../common/alerts/common/enums";
import ApiManager from "../../../common/api/ApiManager.mdl";
import {HistoryReportGetUrlAction} from "../common/enums/HistoryReportGetUrlAction";
import UrlsService from "../../../common/router/services/urlsService.mdl";
import {RouterService} from "../../../common/router/services/routerService.mdl";
import ExportToCsvManager from "../../../common/managers/ExportToCsvManager.mdl";
import ReportingBuildManager from "./reportingBuildManager.mdl";
import axios from "axios";
import {HistoryReportsColumnsManager} from "./historyReportsColumnsManager.mdl";
import Hook from "../../../common/utils/Hook";

@define()
@singleton()
export class HistoryReportsManager extends BaseReportsManager {

    @observable public isOffline: boolean;
    @observable public selectedReport: IReportHistory;
    @observable public isLoadingReportFromUrls: boolean;
    @observable public lastHistoryReports: { item: IReportHistory, report: any, date: number }[];

    @inject() private sessionManager: SessionManager;
    @inject() private userManager: UserManager;
    @inject() private socketManager: SocketManager;
    @inject() private reportingParamsManager: ReportingParamsManager;
    @inject() private reportingBuildManager: ReportingBuildManager;
    @inject() private alertsManager: AlertsManager;
    @inject() private apiManager: ApiManager;
    @inject() private routerService: RouterService;
    @inject() private exportToCsvManager: ExportToCsvManager;
    @inject() private historyReportsColumnsManager: HistoryReportsColumnsManager;

    constructor() {
        super();
        this.lastHistoryReports = [];
        makeObservable(this);
    }

    @init()
    protected initialize() {
        this.startListenToSocketConnected();
    }

    public async get(): Promise<void> {
        try {
            this.reportingParamsManager.setStorageParams();

            if (this.reportingParamsManager.params.isCompare) {
                return this.getCompare();
            }

            return this.getReport();

        } catch (e) {
            await this.hooks.onReportError.call()
        }
    }

    private onCreateReportSuccess(item: IReportHistory) {
        if (item.fileIds && item.fileIds.length > 0) {
            this.getReportFromReportingHistoryUrls(item, HistoryReportGetUrlAction.Generate);
        } else {
            const prepared = this.historyReportsColumnsManager.prepareItem(item);
            this.setSelectedReport(prepared);
            this.historyReportsColumnsManager.items.data.unshift(prepared);
        }
    }

    public async getReport() {

        const params = this.reportingParamsManager.preparedParams;
        const originFields = this.reportingParamsManager.prepareOriginFields;

        const item = await this.socketManager.historyReports.getReporting({
            params,
            originFields,
        });

        this.onCreateReportSuccess(item);
    }

    public async getCompare() {

        const params = this.reportingParamsManager.preparedParams;
        const originFields = this.reportingParamsManager.prepareOriginFields;

        const item = await this.socketManager.historyReports.getCompareReportingHistory({
            params,
            originFields,
        });
        this.onCreateReportSuccess(item);
    }

    @computed
    public get allowedHistoryReports() {
        return !this.sessionManager.userHasAllowedMpPublishers &&
            !this.isOffline &&
            this.userManager.isVidazooOrPerionOrganization;
    }

    public getReportFromReportingHistoryUrls = async (item: IReportHistory, getUrlAction: HistoryReportGetUrlAction) => {
        if (this.isLoadingReportFromUrls) {
            this.alertsManager.setBasicAlert({
                content: "Please wait... We already try to get report!",
                ttl: 3000
            }, AlertAccent.Error);
            return;
        }
        try {
            this.hooks.onLoadingReport.call();

            this.reportingParamsManager.setParamsFromHistoryReport(item);
            this.reportingBuildManager.setRequestedParams(this.reportingParamsManager.params);

            this.isLoadingReportFromUrls = true;
            const url = await this.apiManager.historyReports.getReportFromUrls(item._id)
            this.onLoadReportFromUrlsSuccess(url, getUrlAction, item)
        } catch (e) {
            this.onLoadReportFromUrlsError()
        }
    };

    public onLoadReportFromUrlsSuccess = (url: string, getUrlAction: HistoryReportGetUrlAction, item: IReportHistory) => {
        if (url) {
            axios.get(url).then((reportRes) => {
                switch (getUrlAction) {
                    case HistoryReportGetUrlAction.Reuse:
                        this.setSelectedReport(item);
                        this.onReportReady({item, report: reportRes.data, date: Date.now()}, true);
                        break;
                    case HistoryReportGetUrlAction.Generate:
                        this.setSelectedReport(item);
                        this.onReportReady({item, report: reportRes.data, date: Date.now()});
                        break;
                    case HistoryReportGetUrlAction.Download:
                        this.exportHistoryReport(reportRes.data, item.params);
                        break;
                }
            });
        }
    };

    public startListenToSocketConnected = () => {
        if (this.socketManager.historyReports.isConnected) {
            this.onSocketConnected();
        } else {
            this.socketManager.historyReports.hooks.socketConnected.tap(this.onSocketConnected, null, this);
        }
    }

    public onSocketConnected = () => {
        if (this.userManager._id) {
            this._startListenToReportHistoryIsReady();
        } else {
            this.sessionManager.hooks.initCompleted.tap(this._startListenToReportHistoryIsReady, null, this);
        }
    }

    public _startListenToReportHistoryIsReady() {
        this.socketManager.historyReports.startListenToReportHistoryIsReady(this.userManager._id, (data) => this.onReportReady(data));
    }

    public setSelectedReport = (item: IReportHistory) => {
        this.routerService.push(UrlsService.historyReport(item._id));
        this.selectedReport = item;
    };

    public clearSelectedReport = () => {
        this.selectedReport = null;
        this.routerService.push(UrlsService.reporting());
    }

    private async onReportReady(data: { item: IReportHistory, report: any, date: number }, setParams: boolean = false) {
        this.isLoadingReportFromUrls = false;

        data.date = Date.now();
        this.lastHistoryReports.push(data);
        if (this.lastHistoryReports.length > 5) {
            this.lastHistoryReports.shift();
        }

        if (this.selectedReport && this.selectedReport._id === data.item._id) {
            await this.setReport(data, setParams);
        }
    }

    public setReport = async (data: {
        item: IReportHistory,
        report: any,
        date: number
    }, setParams: boolean = false) => {
        setParams && await this.reportingParamsManager.setParamsFromHistoryReport(data.item);
        await this.hooks.onReportReady.call(data.report)
    }

    public exportHistoryReport = (report: any[], params: IReportHistoryParams) => {
        this.alertsManager.setBasicAlert({
            content: "Generating CSV, Please wait...",
            ttl: 5000
        })

        this.exportToCsvManager.exportHistoryReport(report, params);
        this.isLoadingReportFromUrls = false;
    };

    public onLoadReportFromUrlsError = () => {
        this.isLoadingReportFromUrls = false;

        this.alertsManager.setBasicAlert({
            content: "Oops! Load Report From Urls Failed",
            ttl: 10000
        }, AlertAccent.Error);
    };
}
