import React from "react";
import theme from "../../../../../../common/styles/_baseForm.module.scss";
import {observer} from "mobx-react";
import {
    AggregateChangeComponent,
    Checkbox,
    Chips,
    FormInput,
    FormSelect,
    Spacer,
    TimezonePicker
} from "@vidazoo/ui-kit";
import {normalizeEventValue} from '../../../../../../common/utils/utils';
import {ScheduleReportCreateModel} from '../../../create/model/ScheduleReportCreateModel.mdl';
import {ScheduleReportEditModel} from '../../../edit/model/ScheduleReportEditModel.mdl';
import {ReportVerticalTypes} from '../../../../../../common/enums/ReportVerticalType';
import {DateRanges, ReportDelimiters, ReportSchedules} from '../../../../common/scheduleReportEnums';

export interface IDetailsFormProps {
    model: ScheduleReportCreateModel | ScheduleReportEditModel;
}

export default observer(function DetailsForm(props: IDetailsFormProps) {
    const {model} = props;
    const item = model.item.data;

    const onAddRecipient = (value: string, label: string, isNew: boolean, name: string) => {
        model.item.addToParamArray("recipients", value);
    };

    const onRemoveRecipient = (value: string, label: string, name: string) => {
        model.item.removeFromArray(model.item.data.recipients, value);
    };

    const onChangeDetail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = normalizeEventValue(e);
        model.item.updateParam(e.target.name, value);
    };

    return (
        <div className={theme.form}>
            <AggregateChangeComponent onChange={onChangeDetail}>
                <div className={theme.row}>
                    <FormInput value={item.name} autoFocus className={theme.input} name="name" label="Name" required
                               fill/>
                </div>
                <div className={theme.row}>
                    <FormInput value={item.description} className={theme.input} name="description"
                               label="Description" fill/>
                </div>
                <div className={theme.row}>
                    <FormSelect items={ReportVerticalTypes} fill maxItems={5}
                                searchable required name="verticalType" label="Vertical Type"
                                value={item.verticalType}
                                className={theme.input} itemValueKey="value" itemLabelKey="label"/>
                </div>
                <div className={theme.row}>
                    <FormSelect value={item.schedule} name="schedule" className={theme.input} fill label="Schedule"
                                itemLabelKey="label" itemValueKey="value" required items={ReportSchedules}
                                maxItems={5}/>
                </div>
                <div className={theme.row}>
                    <Chips name="recipients" searchPlaceholder="Add Recipient Email" allowCreate={true}
                           allowOrder={false} onSelect={onAddRecipient} onDelete={onRemoveRecipient}
                           chips={item.recipients}/>
                </div>
                <div className={theme.row}>
                    <FormSelect value={item.dateRange} name="dateRange" className={theme.input} fill
                                label="Date Range" itemLabelKey="label" itemValueKey="value" required
                                items={DateRanges} maxItems={5}/>
                    <TimezonePicker timezone={item.timezone} name="timezone" searchable
                                    required className={theme.input}/>
                    <FormSelect value={item.delimiter} name="delimiter" className={theme.input} fill
                                label="Delimiter" itemLabelKey="label" itemValueKey="value" required
                                items={ReportDelimiters}/>
                </div>
                <div className={theme.row}>
                    <Spacer right={48}>
                        <Checkbox onChange={onChangeDetail} defaultChecked={item.isActive} name="isActive" label="Active"/>
                    </Spacer>
                    <Spacer right={48}>
                        <Checkbox defaultChecked={item.sendEmpty} name="sendEmpty"
                                  label="Send report even if empty"/>
                    </Spacer>
                </div>
            </AggregateChangeComponent>
        </div>
    );
});
