import {Button, useToggle, ButtonsList, debounce, Attention} from '@vidazoo/ui-kit';
import HistoryReportsButton from '../historyReportsButton/HistoryReportsButton';
import theme from './theme.module.scss';
import React, {useCallback, useEffect, useState} from 'react';
import useReportingParamsManager from '../../common/hooks/useReportingParamsManager';
import {observer} from 'mobx-react';
import useReportingModel from '../../common/hooks/useReportingModel';
import PresetsSelect from "../presetsSelect/PresetsSelect";
import CreatePresetModel from "../createPresetModel/CreatePresetModel";
import LastHistoryReports from "../lastHistoryReportsSelect/LastHistoryReports";

const minWindowWidthToGroupActions = 1400;

function ReportingActions() {
    const {resetParams} = useReportingParamsManager();
    const model = useReportingModel();
    const {executionTime, generateReportLink, downloadCSV} = model;

    const [echoButtons, setEchoButtons] = useState(shouldEchoButtons(window.innerWidth));

    function shouldEchoButtons(windowWidth: number): boolean {
        return minWindowWidthToGroupActions > 0
            ? windowWidth > minWindowWidthToGroupActions
            : true;
    }

    const debouncedOnWindowResize = useCallback(debounce(() => {
        requestAnimationFrame(() => {
            const ww = window.innerWidth;
            setEchoButtons(shouldEchoButtons(ww));
        });
    }, 100), [minWindowWidthToGroupActions]);

    useEffect(() => {
        if (minWindowWidthToGroupActions) {
            window.addEventListener("resize", debouncedOnWindowResize, false);
        }

        return () => {
            if (minWindowWidthToGroupActions) {
                window.removeEventListener("resize", debouncedOnWindowResize, false);
            }
        };
    }, [debouncedOnWindowResize]);

    return (
        <div>
            <div className={theme.actions}>
                <div>
                    <ButtonsList label="Reporting Actions" echo={echoButtons}>
                        <div className={theme.buttons}>
                            <Button iconName="icon-download" medium flat disabled={model.isLoading}
                                    onClick={downloadCSV}>
                                Download CSV
                            </Button>
                            <Button iconName="icon-link" medium flat onClick={generateReportLink}>Copy Link</Button>
                        </div>
                    </ButtonsList>
                </div>
                <PresetsSelect/>
                {model.useHistoryReports && <LastHistoryReports/>}
                <div>
                    {executionTime ? (
                        <Attention label={`Last execution time: ${Math.ceil(executionTime / 1000)}s`}/>
                    ) : null}
                </div>
                <div className="flex flex-align-center">
                    <Button type="button" uppercase gray medium onClick={resetParams}>Clear All</Button>
                    <HistoryReportsButton/>
                    <Button type="submit" uppercase blue medium>
                        Generate Report
                    </Button>
                </div>
            </div>
            <CreatePresetModel/>
        </div>
    )
}

export default observer(ReportingActions);
