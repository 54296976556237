import * as React from "react";
import {Button, CircleLoader, Icon, Label, LinkButton, Loader, SearchBar, Surface} from "@vidazoo/ui-kit";
import theme from "./theme.module.scss";
import clsx from "clsx";
import dayjs from "dayjs";
import {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import useHistoryReportsManager from "../../common/hooks/useHistoryReportsManager";

function LastHistoryReports() {

    const { lastHistoryReports, setReport } = useHistoryReportsManager();

    const [isOpen, setIsOpen] = React.useState(false);
    const ref = useRef(null);

    const toggleIsOpen = (e?: any) => {
        if (e) {
            e.stopPropagation();
        }
        setIsOpen(prevState => !prevState);
    };

    const isClickOutsideRef = (e) => {
        return isOpen && ref?.current?.contains && !ref.current.contains(e.target);
    };

    const onWindowClick = (e) => {
        if (isClickOutsideRef(e)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            window.addEventListener('click', onWindowClick);
            return () => window.removeEventListener('click', onWindowClick);
        }
    }, [isOpen]);

    const onSelect = (item: any) => {
        setReport(item, true);
        toggleIsOpen();
    };

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <div onClick={toggleIsOpen} className={clsx(theme.presetsLabel, {[theme.open]: isOpen})}>
                Last Reports
                <Icon className={clsx(theme.icon, {[theme.open]: isOpen})} size={1}
                      iconName="icon-arrow-down-small"/>
            </div>
            {isOpen ? (
                <Surface ref={ref} className={theme.presetsModal} level={3}>
                    <div className={theme.presetsList}>
                        {lastHistoryReports
                            .map((item) => {
                                return (
                                    <div onClick={() => onSelect(item)}
                                         key={item.date}
                                         className={theme.presetRow}>
                                        <div
                                            className={theme.presetRowName}>
                                            {dayjs(item.date).format("DD/MM, HH:mm:ss")}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </Surface>
            ) : null}
        </div>
    );
}

export default observer(LastHistoryReports);
